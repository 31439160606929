<template>
  <div class="table-responsive">
    <table class="table table-sm small">
      <thead v-if="accountsData.length > 0" class="table-light">
        <tr>
          <th id="name" class="fit">NAME</th>
          <th id="account_id" class="fit">ACCOUNT ID</th>
          <th id="contact_info" class="fit">CONTACT INFORMATION</th>
        </tr>
      </thead>
      <tbody v-if="accountsData.length > 0">
        <tr v-for="(data, index) in accountsData" :key="index">
          <td>{{ data.name }}</td>
          <td>{{ data.id }}</td>
          <td>{{ data.email }}</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td>No accounts</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "AccountList",
  props: {
    accountsData: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 1800px) {
  .fit {
    white-space: nowrap;
    width: 1%;
  }
}
</style>
