<template>
  <div class="st-view-wrapper">
    <div class="st-view-header-wrapper">
      <div class="st-view-header-content">
        <router-link class="button breadcrumbs-item" to="/admin">
          Accounts
        </router-link>
        <span class="breadcrumbs-item">&lt;</span>
        <span class="text-muted">{{ providerName }}</span>

        <div class="st-metadata-wrapper">
          <div class="text-muted st-header-title-tag">PROVIDER NAME</div>
          <h5 class="st-header-title" data-testid="provider-name">
            {{ providerName }}
          </h5>
        </div>
      </div>
    </div>
    <div v-if="loading">Loading...</div>
    <div v-else-if="error">Error: {{ error.message }}</div>
    <div v-else-if="accounts" class="st-view-body-wrapper">
      <div class="st-view-body-content">
        <AccountList :accounts-data="accounts" class="account-table" />
        <PaginationApi
          v-if="hasAccounts"
          :page-size="state.pageSize"
          :handle-page-size-change="handlePageSizeChange"
          :has-previous-page="hasPreviousPage"
          :has-next-page="hasNextPage"
          :load-more="loadMore"
          class="mb-5"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AccountList from "../components/AccountList.vue";
import PaginationApi from "@/components/PaginationApi.vue";
import { computed, reactive } from "vue";
import { useRoute } from "vue-router";
import { useQuery, useResult } from "@vue/apollo-composable";
import allAccountsQuery from "../graphql/allAccounts.query.gql";
export default {
  name: "ProviderDetail",
  components: {
    AccountList,
    PaginationApi,
  },
  setup() {
    const route = useRoute();
    const providerName = computed(() => route.params.providerName);
    const state = reactive({
      pageSize: 10,
    });

    const { result, loading, error, fetchMore } = useQuery(
      allAccountsQuery,
      {
        provider: providerName,
        first: computed(() => parseInt(state.pageSize)),
        last: computed(() => parseInt(state.pageSize)),
        after: "",
        before: "",
        filter: "",
      },
      {
        notifyOnNetworkStatusChange: true, //For loading in useQuery to work with fetchMore
      }
    );
    const accounts = useResult(result, [], (data) =>
      data.accounts.edges.map((edge) => edge.node)
    );

    const hasAccounts = computed(() => accounts.value.length > 0);

    const pageInfo = useResult(
      result,
      { hasPreviousPage: false, hasNextPage: false },
      (data) => data.accounts.pageInfo
    );

    const hasPreviousPage = computed(() => pageInfo.value.hasPreviousPage);
    const hasNextPage = computed(() => pageInfo.value.hasNextPage);
    const startCursor = computed(() => pageInfo.value.startCursor);
    const endCursor = computed(() => pageInfo.value.endCursor);

    const loadMore = (dir) => {
      fetchMore({
        variables: {
          first: state.pageSize,
          last: state.pageSize,
          before: (dir === "previous" && startCursor.value) || "",
          after: (dir === "next" && endCursor.value) || "",
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          // No new feed posts
          if (!fetchMoreResult) return previousResult;

          // Concat previous feed with new feed posts
          return {
            ...previousResult,
            ...fetchMoreResult,
          };
        },
      });
    };

    const handlePageSizeChange = (pageSize) => {
      if (pageSize) {
        state.pageSize = pageSize;
      }
    };

    return {
      state,
      loading,
      error,
      accounts,
      providerName,
      handlePageSizeChange,
      hasAccounts,
      hasPreviousPage,
      hasNextPage,
      loadMore,
    };
  },
};
</script>

<style lang="scss" scoped>
.st-header-title {
  margin-top: 8px;
}

.account-table {
  margin-top: 50px;
}
</style>
